<template>
  <div>
    <span v-if="parseInt(value.fac_24)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-hours-24
      </v-icon>
      24 hours service
    </span>
    <span v-if="parseInt(value.fac_breakfast)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-silverware-fork-knife
      </v-icon>
      Breakfast
    </span>
    <span v-if="parseInt(value.fac_wifi)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-access-point-network
      </v-icon>
      Wifi
    </span>
    <span v-if="parseInt(value.fac_ac)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-snowflake
      </v-icon>
      Air Conditioner
    </span>
    <span v-if="parseInt(value.fac_balcony)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-balcony
      </v-icon>
      Balcony
    </span>
    <span v-if="parseInt(value.fac_tv)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-television
      </v-icon>
      Television
    </span>
    <span v-if="parseInt(value.fac_tv_cable)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-monitor-share
      </v-icon>
      Cable TV
    </span>
    <span v-if="parseInt(value.fac_bathtub)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-shower
      </v-icon>
      Bathtub
    </span>
    <span v-if="parseInt(value.fac_water_heater)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-water-thermometer-outline
      </v-icon>
      Water Heater
    </span>

    <span v-if="parseInt(value.fac_bathrobe)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-bus-stop-covered
      </v-icon>
      Bathrobe
    </span>
    <span v-if="parseInt(value.fac_desk)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-desk
      </v-icon>
      Desk
    </span>
    <span v-if="parseInt(value.fac_hair_dryer)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-hair-dryer
      </v-icon>
      Hair Dryer
    </span>
    <span v-if="parseInt(value.fac_inroom_safe)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-scale-bathroom
      </v-icon>
      In Room Safe
    </span>
    <span v-if="parseInt(value.fac_microwave)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-microwave
      </v-icon>
      Microwave
    </span>
    <span v-if="parseInt(value.fac_minibar)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-glass-mug-variant
      </v-icon>
      Minibar
    </span>
    <span v-if="parseInt(value.fac_shower)" :class="[itemClass]" class="d-inline-flex align-center secondary--text text--darken-2 caption">
      <v-icon small class="mr-1" color="secondary">
        mdi-shower-head
      </v-icon>
      Shower
    </span>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    itemClass: {
      type: String,
      default: 'my-1 mr-2'
    }
  }
}
</script>
